<template>
  <v-main class="main-sub-header caller-main-head">
    <audio muted autoplay="" id="test"></audio>
    <v-container
      fluid
      class="d-inline-flex light-green lighten-5 pl-5 pr-5 pt-3"
      id="caller_grid"
    >
    </v-container>
  </v-main>
</template>

<script>
import { BROADCASTING } from "@/constants/services";
import { mapGetters } from "vuex";
export default {
  name: "CurrentBroadcasts",
  data() {
    return {
      broadCastingService: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  mounted() {
    this.broadCastingService = this.user.services.findIndex(
      (val) => val.id === BROADCASTING
    );
  },
};
</script>
