<template>
  <v-container fluid class="pa-0"> 
    <v-card class="d-flex flex-column fill-height">
      <v-card-title class="chat-header">
                  <label>
                    <span>{{ $t("navbar.coreBuying") }}</span>
                  </label>
                  <v-spacer />
                  <v-btn height="30" small class="mr-0
                    log-out
                    create-post-btn
                    post-reply-btn
                    green
                    darken-4
                    green-gradient
                    white--text" style="height: 40px !important;" @click="openDialog">
                    <v-icon>mdi-plus</v-icon>
                    {{ $t("coreBuying.addList") }}
                  </v-btn>
      </v-card-title>
      <v-row class="no-gutters elevation-0">
        <v-col primary xs="12" sm="12">
          <div class="custom-card" style="box-shadow: none;">
            <div class="custom-box">
                    <v-data-table class="core_buyer_table"  :header-props="{ sortIcon: null }" :headers="headers" :items="coreBuyingList.data"
                      height="calc(100vh - 364px)" :single-expand="singleExpand" :expanded.sync="expanded" fixed-header
                      :disable-sort="true" :show-expand="false" :loading="loading" :page.sync="page"
                      :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
                      <template v-slot:item.created_at="{ item }">
                        {{ item.created_at | MMDDYYdatefilter }}
                      </template>
                      <template v-slot:item.id="{ item }">
                        {{ item.id }}
                      </template>
                      <template v-slot:item.name="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item.parts="{ item }">
                        {{ getPartsJoin(item.parts) }}
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-row>
                          <v-btn height="40" small color="green darken-4 green-gradient white--text" class="mr-2"
                            @click="showEditDailog(item)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn height="40" small color="red darken-4 red-gradient white--text" class="mr-2" @click="
                            deletedCoreBuying = item;
                          dialogDelete = true;
                          ">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-row>
                      </template>
                    </v-data-table>
                    <div class="text-center">
                      <v-pagination v-model="page" :length="pageCount" @input="fetchCoreBuying"></v-pagination>
                    </div>
                    <v-dialog hide-overlay :attach="true" v-model="coreBuyingDialog" max-width="700px"
                      :content-class="'hide-overflow-y image-slider'" persistent>
                      <v-card>
                        <ApiErrorMessage :message="apiErrorMessage" />
                        <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
                          <div class="pa-2">
                            <v-card-title v-if="!isCoreBuyingEdit"> {{ $t("coreBuying.addTitle")
                              }}</v-card-title>
                            <v-card-title v-if="isCoreBuyingEdit"> {{ $t("coreBuying.editTitle")
                              }}</v-card-title>
                            <v-form>
                              <v-container>
                                <v-form class="mr-3">
                                  <v-row>
                                    <v-col cols="12" md="12">
                                      <v-text-field hide-details="auto" v-model="coreBuyingListForm.title"
                                        @input="$v.coreBuyingListForm.title.$touch()"
                                        @blur="$v.coreBuyingListForm.title.$touch()" :label="$t('coreBuying.titleList')"
                                        outlined :error-messages="titleListErrors" :maxlength="100"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                      <v-autocomplete v-model="coreBuyingListForm.parts" :items="partsArrayList"
                                        :label="$t('coreBuying.parts')" :value="coreBuyingListForm.parts"
                                        item-text="Description" item-value="Description"
                                        @input="$v.coreBuyingListForm.parts.$touch()"
                                        @blur="$v.coreBuyingListForm.parts.$touch()" :error-messages="partsErrors" outlined return-object
                                        clearable multiple deletable-chips small-chips hide-selected hide-no-data
                                        hide-details :menu-props="{
                                          closeOnContentClick: true,
                                        }">
                                      </v-autocomplete>
                                      <!-- return-object -->
                                      Note: <span class="error--text">{{ $t('coreBuying.searchPartsNote') }}</span>
                                    </v-col>
                                    <v-col primary xs="12" sm="12">

                                      <div class="
                                          flex
                                          w-full
                                          h-screen
                                          items-center
                                          justify-center
                                          text-center
                                          fileupload
                                        " id="app" @dragover="dragover" @drop="drop">
                                        <v-progress-circular v-if="excelFileLoader" indeterminate :size="90"
                                          color="green"></v-progress-circular>
                                        <label for="assetsFieldHandle" class="fileuploadlabel ">
                                          <div class="" v-if="!excelFileLoader" :style="'min-height: '+(coreBuyingListForm.file ? 50 : 112)+'px;'">
                                            <input type="file" name="file" id="assetsFieldHandle" @change="onChange"
                                              @input="$v.coreBuyingListForm.file.$touch()" ref="file" accept=".xlsx,.xls" />

                                            {{ $t("coreBuying.uploadImageTitle") }}

                                          </div>
                                        </label>
                                        <div id="file" class="select-image">
                                          <figure v-if="coreBuyingListForm.file" class="file">
                                            <svg fill="#000000" width="60px" height="60px" viewBox="-64 0 512 512"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z" />
                                            </svg>
                                          </figure>
                                        </div>
                                        <a id="file-delIcon" v-if="coreBuyingListForm.file" @click="deleteFile()"
                                          class="delicon" data-tooltip="Delete">
                                          <img src="../assets/delete.svg" height="30" />
                                        </a>
                                      </div>

                                      <span class="error--text">{{ fileErrors[0] }}</span>
                                    </v-col>
                                  </v-row>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize"
                                      @click="coreBuyingDialog = !coreBuyingDialog; isCoreBuyingEdit = false;">
                                      {{ $t("coreBuying.close") }}
                                    </v-btn>
                                    <v-btn height="40" large
                                      color="green darken-4 green-gradient white--text text-capitalize" :loading="saving"
                                      @click="saveCoreBuyingList"
                                      :disabled="!coreBuyingListForm.title || !coreBuyingListForm.parts">
                                      {{ !isCoreBuyingEdit ? $t("coreBuying.addList") :
                                        $t("coreBuying.updateList") }}
                                    </v-btn>
                                  </v-card-actions>
                                </v-form>
                              </v-container>
                            </v-form>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <confirmation-dialog @close="dialogDelete = false" @confirm="deleteCoreBuyingConfirm"
                      :dialog="dialogDelete" :dialogHeadline="$t('coreBuying.deleteHeadline')"></confirmation-dialog>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
// Janus Related files
import "@/plugins/yoyo";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import { mapActions, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import numberUtility from "@/utils/number.utility";
import { ITEMS_PER_PAGE } from "@/constants/common";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    ConfirmationDialog,
    GooglePlace: () => import("@/components/common/GooglePlace"),
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },

  data() {
    return {
      saving: false,
      error: "",
      loading: false,
      excelFileLoader: false,

      coreBuyingListForm: {
        id: null,
        title: null,
        parts: [],
        file: null
      },
      errors: {
        title: null,
        file: null,
        parts: null
      },

      panel: [0],
      loading: false,
      coreBuyingDialog: false,
      isCoreBuyingEdit: false,
      page: 1,
      searchText: "",
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      saving: false,
      dialogDelete: false,
      deletedCoreBuying: {},

    };
  },
  validations: {
    coreBuyingListForm: {
      title: { required },
      parts: {
        required
      },
      file: { required }
    }
  },
  computed: {
    ...mapGetters({
      fileResponse: "posts/getFileResponse",
      coreBuyingList: "coreBuying/getCoreBuying",
      partsArrayList: "interchange/getPType",
    }),
    headers() {
      return [
        {
          text: this.$t("coreBuying.date"),
          align: "left",
          value: "created_at",
          width: "20%",
        },
        {
          text: this.$t("coreBuying.id"),
          align: "left",
          value: "id",
          width: "10%",
        },
        {
          text: this.$t("coreBuying.titleList"),
          align: "left",
          value: "title",
          width: "18%",
        },
        {
          text: this.$t("coreBuying.parts"),
          align: "left",
          value: "parts",
          width: "32%",
        },
        {
          text: this.$t("coreBuying.action"),
          align: "left",
          value: "action",
          width: "15%",
        },
      ];
    },
    titleListErrors() {
      const errors = [];
      if (!this.$v.coreBuyingListForm.title.$dirty) return errors;
      !this.$v.coreBuyingListForm.title.required &&
        errors.push(this.$t("coreBuying.validations.titleIsRequired"));
      if (this.errors && this.errors.title) {
        return this.errors.title;
      }
      return errors;
    },
    partsErrors() {
      const errors = [];
      if (!this.$v.coreBuyingListForm.parts.$dirty) return errors;
      !this.$v.coreBuyingListForm.parts.required &&
        errors.push(this.$t("coreBuying.validations.partsIsRequired"));
      if (this.errors && this.errors.parts) {
        return this.errors.parts;
      }
      return errors;
    },
    fileErrors() {
      const errors = [];
      if (!this.$v.coreBuyingListForm.file.$dirty) return errors;
      !this.$v.coreBuyingListForm.file.required &&
        errors.push(this.$t("coreBuying.validations.fileIsRequired"));
      if (this.errors && this.errors.file) {
        return this.errors.file;
      }
      return errors;
    },
  },
  async mounted() {
    this.fetchParts();
    this.fetchCoreBuying();
  },
  methods: {
    ...mapActions({
      uploadFile: "posts/uploadFile",
      getCoreBuying: "coreBuying/getCoreBuying",
      saveCoreBuying: "coreBuying/saveCoreBuying",
      updateCoreBuying: "coreBuying/updateCoreBuying",
      deleteCoreBuying: "coreBuying/deleteCoreBuying",
      getPType: "interchange/getPType",
    }),

    async saveFile(file, tag) {
      try {
        this.excelFileLoader = true;
        let formData = new FormData();
        await formData.append("file[0]", file);
        await formData.append("file_name[0]", "core-buying-file.xls");
        await formData.append("tag", tag);
        await this.uploadFile(formData);
        this.excelFileLoader = false;
        return this.fileResponse;
      } catch ({ message }) {
        this.$refs[tag].$el.children[1].value = "";
        this.apiErrorMessage = message;
      }
    },

    async onChange() {
      try {
        var fileExt = (/[.]/.exec(this.$refs.file.files[0].name)) ? /[^.]+$/.exec(this.$refs.file.files[0].name) : undefined;
        if (fileExt && fileExt != undefined && fileExt[0]) {
          var selectedFile = await this.saveFile(this.$refs.file.files[0], 'excel');
          this.coreBuyingListForm.file = selectedFile.data;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async deleteFile() {
      this.coreBuyingListForm.file = null;
    },

    dragover(event) {
      event.preventDefault();
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },

    async fetchParts() {
      this.loading = true;
      try {
        await this.getPType();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async fetchCoreBuying() {
      this.loading = true;
      try {
        if (this.searchText !== "") {
          this.page = 1;
        }
        await this.getCoreBuying({
          search: this.searchText,
          items_per_page: ITEMS_PER_PAGE,
          page: this.page,
        });

        if (this.coreBuyingList.meta) {
          this.itemsPerPage = parseInt(this.coreBuyingList.meta.per_page);
          this.pageCount = this.coreBuyingList.meta.last_page;
        } else {
          this.itemsPerPage = this.coreBuyingList.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async saveCoreBuyingList() {
      this.$v.coreBuyingListForm.$touch();
      if (!this.$v.coreBuyingListForm.$invalid) {
        try {
          this.saving = true;
          var data = _.cloneDeep(this.coreBuyingListForm);
          data.parts = JSON.stringify(data.parts);
          data.media_id = this.coreBuyingListForm.file?.id ? this.coreBuyingListForm.file?.id : '';
          delete data.file;
          if (this.isCoreBuyingEdit && this.coreBuyingListForm.id) {
            await this.updateCoreBuying(data);
          } else {
            await this.saveCoreBuying(data);
          }
          this.resetFormData();
          this.coreBuyingDialog = false;
          this.isCoreBuyingEdit = false;
        } catch ({ message }) {
          this.errors.name = message.name;
          this.errors.number = message.number;
          this.errors.parts = message.parts;
          // this.apiErrorMessage = message;
        } finally {
          this.saving = false;
        }
      }
    },
    async deleteCoreBuyingConfirm() {
      this.$store.commit("setLoader", true);
      try {
        let data = {
          id: this.deletedCoreBuying.id,
          type: this.coreBuyingListForm.type
        }
        await this.deleteCoreBuying(data);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        // showSnackbar(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
      this.dialogDelete = false;
    },
    openDialog() {
      this.coreBuyingDialog = true;
      this.resetFormData();
    },
    showEditDailog(item) {
      this.isCoreBuyingEdit = true;
      this.resetFormData();
      this.coreBuyingListForm.id = item.id;
      this.coreBuyingListForm.title = item.title;
      this.coreBuyingListForm.parts = item.parts ? item.parts.split(', ') : [];
      this.coreBuyingListForm.file = item.media ? item.media : null;
      this.coreBuyingDialog = true;
    },
    resetFormData() {
      this.coreBuyingListForm = {
        title: null,
        parts: [],
        file: null
      }
      this.$v.$reset()
    },
    getPartsJoin(parts) {
      if(parts && parts.length > 0) {
        return parts.map(part => part.Description).join(', ');
      }
    }
  },
};
</script>