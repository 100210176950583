<template>
  <div>
    <v-navigation-drawer
      v-if="!$vuetify.breakpoint.mdAndUp"
      v-model="sideNav"
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-bind:width="220"
      color="green darkar-4 green-gradient"
      mobile-breakpoint="960"
      :right="$vuetify.rtl"
      mini-variant-width="80"
      app
      id="main-sidebar"
      dark
    >
      <v-list>
        <!---USer Area -->
        <v-list-item
          v-for="item in menus"
          :key="item.title"
          :to="item.link"
          :active-class="`primary white--text`"
          linkd
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!---Sidebar Items -->
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      dense
      flat
      color="green darkar-4 green-gradient"
      class="pd-5 main-header"
      height="80px"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="sideNav = !sideNav"
        color="white"
      ></v-app-bar-nav-icon>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          exact
          color="white"
          :class="
            item.title.toLowerCase() == `navbar.${$route.name}`.toLowerCase() ||
            (item.title === 'navbar.marketingCampaign' &&
              [
                'MarketingCampaign',
                'MarketingPhone',
                'MarketingEmail',
                'AddCampaign',
                'HistoryCampaign',
              ].includes($route.name)) ||
            (item.title === 'navbar.hotlineNews' &&
              ['HotlineNews', 'ViewHotlineNews'].includes($route.name))
              ? ` v-btn--active flex-column-class`
              : `flex-column-class`
          "
          text
          v-for="item in user.user_type == 3 ? [] : menus"
          :key="item.title"
          @click="conformDailogOpen(item.link)"
        >
          <!-- :to="item.link" -->
          <v-icon
            v-if="item.title != 'navbar.hotlineNews'"
            size="22"
            left
            medium
            :class="
              item.title === 'navbar.membersDirectory' ||
              item.title === 'navbar.carHistoryReport' ||
              item.title === 'navbar.vinSearch' ||
              item.title === 'navbar.interchange' ||
              item.title === 'navbar.booklog' ||
              item.title === 'navbar.marketingCampaign' ||
              item.title === 'navbar.searchInventory' ||
              item.title === 'navbar.coreBuyingMember' ||
              item.title === 'navbar.invoice'
                ? 'mb-2'
                : 'mb-2 coming-soon-menu-color'
            "
          >
            {{ item.icon }}
          </v-icon>
          <v-badge
            v-if="item.title === 'navbar.hotlineNews'"
            bordered
            :content="newsTotalUnreadCount"
            :value="newsTotalUnreadCount"
            color="red"
          >
            <v-icon
              size="22"
              left
              medium
              :class="
                item.title === 'navbar.hotlineNews'
                  ? 'mb-2'
                  : 'mb-2 coming-soon-menu-color'
              "
            >
              {{ item.icon }}
            </v-icon>
          </v-badge>
          <p v-html="$t(item.title)"></p>
        </v-btn>
        <v-btn
          exact
          color="white"
          :class="
            item.title.toLowerCase() == `navbar.${$route.name}`.toLowerCase()
              ? `v-btn--active flex-column-class`
              : `flex-column-class`
          "
          text
          v-for="item in user.user_type == 3 ? user3Menu : []"
          :key="item.title"
          :to="
            item.title.toLowerCase() == 'navbar.carhistoryreport'
              ? ''
              : item.link
          "
          :href="item.link"
          :target="
            item.title.toLowerCase() == 'navbar.carhistoryreport'
              ? '_blank'
              : '_self'
          "
        >
          <v-icon
            size="22"
            left
            medium
            :class="
              item.title === 'navbar.membersDirectory' ||
              item.title === 'navbar.carHistoryReport' ||
              item.title === 'navbar.coreBuying'
                ? 'mb-2'
                : 'mb-2 coming-soon-menu-color'
            "
          >
            {{ item.icon }}
          </v-icon>
          <p v-html="$t(item.title)"></p>
        </v-btn>
        <v-btn
          exact
          color="white"
          class="flex-column-class"
          text
          :href="websiteServicesLink"
          target="_blank"
        >
          <v-icon left medium class="mb-2" style="color: #f4d03f"
            >mdi-web</v-icon
          >
          <p style="font-size: 15px; display: contents; color: #f4d03f">
            Website <br />
            Services
          </p>
        </v-btn>
        <v-btn
          v-if="user.user_type != 3"
          exact
          color="white"
          class="flex-column-class"
          text
          :href="inventoryUrl + `?auth_details_user=${user.id}`"
          target="_blank"
        >
          <v-icon left medium class="mb-2" style="color: #f4d03f"
            >mdi-text-box-search</v-icon
          >
          <p style="font-size: 15px; color: #f4d03f">
            Inventory <br />
            login
          </p>
        </v-btn>
      </v-toolbar-items>
      <!-- <v-btn medium class="online-members ml-2" elevation="0">
        <i>Online Members</i> <span id="participantCount"> 0 </span>
      </v-btn>-->

      <v-spacer></v-spacer>

      <!-- user profile -->
      <!--  TODO: Hide this button until further notice   -->
      <!--    <v-select
      :menu-props="{ top: false, offsetY: true }"
      v-model="lang"
      :item-value="(item) => item.lang"
      :items="items"
      @change="changeLanguage(lang)"
      solo
      class="custom-select language-select mr-4"
    >
      <template v-slot:selection="{ item }">
        <img :src="item.image" width="34" class="" />
      </template>
      <template v-slot:item="{ item }">
        <img :src="item.image" width="25" class="mr-3 mt-2 mb-2 pa-1" /><span>{{
          item.name
        }}</span>
      </template>
      <template v-slot:item="{ item }">
        <img class="ma-2" width="25" :src="item.image" />{{ item.name }}
      </template>
    </v-select>-->
      <v-btn
        exact
        color="white"
        class="flex-column-class"
        text
        style="margin-right: 15px"
      >
        <b size="35" left medium class="mb-2" style="font-size: 22px">
          714-334-5505
        </b>
        <p style="font-size: 20px; display: contents">
          {{ $t("navbar.customerSupport") }}
        </p>
      </v-btn>

      <b style="font-size: 22px; color: #fff; padding-right: 5px">Room</b>
      <v-autocomplete
        class="roomSelect"
        outlined
        v-model="room"
        :items="roomList"
        @change="changeRoom"
        item-text="name"
        item-value="code"
        :label="$t('navbar.room')"
        :placeholder="$t('navbar.room')"
        solo
        :dense="true"
        hide-details="auto"
        return-value
      ></v-autocomplete>
      <!--<v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <a
            class="d-flex user-profile align-center mr-4"
            v-bind="attrs"
            v-on="on"
            @click="$router.push({ name: 'Profile' })"
          >
            <v-avatar rounded size="36">
              <span class="white--text avtar-icon">
                {{ setAvtarIcon() }}
              </span>
            </v-avatar>
          </a>
        </template>
        <span>{{ user.name }}</span>
      </v-tooltip>-->
      <v-btn
        v-if="user.user_type == 1 || user.user_type == 3"
        width="50"
        class="mr-2 log-out"
        :title="user.name"
        @click="$router.push({ name: 'Profile' })"
      >
        <v-avatar rounded size="36">
          <span class="white--text avtar-icon">
            {{ setAvtarIcon() }}
          </span>
        </v-avatar>
      </v-btn>
      <v-btn
        width="50"
        class="mr-2 log-out"
        @click="openChat = true"
        v-if="currentRouteName.includes('dashboard') && user.user_type != 3"
      >
        <v-icon size="28" medium>mdi-chat</v-icon>
        <v-badge
          color="red"
          class="mb-6"
          :content="badgeCount"
          v-if="badgeCount !== 0"
        />
      </v-btn>
      <v-btn width="50" class="mr-0 log-out" @click="doLogout">
        <v-icon size="28" medium>mdi-logout</v-icon>
      </v-btn>
    </v-toolbar>
    <chat
      :show="openChat"
      @countUnseen="getUnseen"
      @updateShow="openChat = false"
      v-if="currentRouteName.includes('dashboard')"
    />
    <v-dialog
      hide-overlay
      :attach="true"
      v-model="conformDailog"
      max-width="700px"
      :content-class="'hide-overflow-y image-slider'"
      persistent
    >
      <v-card>
        <v-card-text
          class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
        >
          <div class="pa-2">
            <v-card-title style="flex-direction: column">
              {{ $t("invoice.confirmChangeMenuText") }}</v-card-title
            >
            <v-container class="text-center">
              <v-card-actions class="mt-3">
                <v-spacer></v-spacer>
                <v-btn
                  height="40"
                  style="font-size: 16px !important"
                  large
                  class="red darken-4 red-gradient white--text text-capitalize"
                  @click="conformDailog = !conformDailog"
                >
                  {{ $t("invoice.no") }}
                </v-btn>
                <v-btn
                  height="40"
                  style="font-size: 16px !important"
                  large
                  class="green darken-4 green-gradient white--text text-capitalize"
                  @click="goToNext()"
                >
                  {{ $t("invoice.yes") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import SocketMixin from "@/mixins/SocketMixin";
import chat from "../chat/Chat";

export default {
  name: "Navbar",
  components: {
    chat,
  },
  mixins: [SocketMixin.listeners],
  data() {
    return {
      room: "",
      badgeCount: 0,
      lang: localStorage.getItem("lang"),
      openChat: false,
      items: [
        {
          name: "English",
          lang: "en",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          name: "Spanish",
          lang: "es",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/128-spain.svg",
        },
      ],
      sideNav: null,
      menus: [
        {
          icon: "mdi-account-multiple",
          title: "navbar.membersDirectory",
          link: "/dashboard/",
        },
        {
          icon: "mdi-account-multiple",
          title: "navbar.carHistoryReport",
          link: "/dashboard/vehicle-history",
        },
        // {
        //   icon: "mdi-credit-card-search",
        //   title: "navbar.vinSearch",
        //   link: "/dashboard/vin-search",
        // },
        {
          icon: "mdi-cogs",
          title: "navbar.interchange",
          link: "/dashboard/interchange",
        },
        {
          icon: "mdi-book-open-variant",
          title: "navbar.booklog",
          link: "/dashboard/booklog",
        },
        {
          icon: "mdi-account-tie-voice",
          title: "navbar.marketingCampaign",
          link: "/dashboard/marketing-campaign",
        },
        {
          icon: "mdi-text-box-search",
          title: "navbar.searchInventory",
          link: "/dashboard/serach-inventory",
        },
        {
          icon: "mdi-receipt",
          title: "navbar.invoice",
          link: "/dashboard/billing-invoice",
        },
        /* {
          icon: "mdi-text-box-multiple",
          title: "navbar.dmv",
          link: "/dashboard/dmv-processing",
        }, */
        /*{
          icon: "mdi-firework",
          title: "navbar.auction",
          link: "/dashboard/car-auctions",
        },
        {
          icon: "mdi-cart",
          title: "navbar.trade",
          link: "/dashboard/trade-car-parts",
        },
        {
          icon: "mdi-cart",
          title: "navbar.sellingEbay",
          link: "/dashboard/selling-ebay",
        },*/
        {
          icon: "mdi-fire",
          title: "navbar.hotlineNews",
          link: "/dashboard/hotline-news",
        },
        {
          icon: "mdi-recycle",
          title: "navbar.coreBuyingMember",
          link: "/core-buying/",
        },
      ],
      user3Menu: [
        {
          icon: "mdi-account-multiple",
          title: "navbar.membersDirectory",
          link: "/dashboard/",
        },
        {
          icon: "mdi-receipt",
          title: "navbar.carHistoryReport",
          link: process.env.VUE_APP_VIN_REPORTS_LINK,
        },
        {
          icon: "mdi-recycle",
          title: "navbar.coreBuying",
          link: "/core-buying-service/",
        },
      ],
      conformDailog: false,
      link: null,
      websiteServicesLink: process.env.VUE_APP_WEBSITE_SERVICES_LINK,
      reportDialog: false,
      reportDialogMessage: "",
      reportData: null,
      inventoryUrl: process.env.VUE_APP_INVENTORY_URL,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      userServices: "user/getUserServices",
      roomList: "members/getRoomList",
      newsTotalUnreadCount: "news/getNewsTotalUnread",
    }),
    currentRouteName() {
      return this.$route.path;
    },
  },

  mounted() {
    this.room = localStorage.getItem("room")
      ? localStorage.getItem("room")
      : null;
    this.getRooms();
    document.getElementById("participantCount").innerText =
      sessionStorage.getItem("onlineUsers")
        ? sessionStorage.getItem("onlineUsers")
        : this.user.online_users_count;
    this.newsTotalUnread();
    this.$root.$on("updateUnreadnews", () => {
      setTimeout(() => {
        this.newsTotalUnread();
      }, 500);
    });
  },

  methods: {
    ...mapActions({
      logout: "user/logout",
      getRooms: "members/getRoomList",
      newsTotalUnread: "news/newsTotalUnread",
    }),

    changeRoom() {
      localStorage.setItem("room", this.room);
      location.reload();
    },

    setAvtarIcon() {
      if (this.user && this.user.user_detail) {
        if (this.user.parent_id) {
          return this.user.user_detail.representative_name.substring(0, 1);
        }
        return this.user.user_detail.company_name.substring(0, 1);
      }
      return "";
    },

    async doLogout() {
      try {
        await this.logout();
        sessionStorage.removeItem("onlineUsers");
        window.location.reload();
      } catch ({ message }) {
        console.log(message);
      }
    },
    async changeLanguage() {
      await loadLanguageAsync(this.lang);
      localStorage.setItem("lang", this.lang);
    },

    getUnseen(data) {
      if (data != 0 && data != this.badgeCount) {
        const sound = require("../../assets/chattone.mp3");
        new Audio(sound).play();
      }
      this.badgeCount = data;
    },
    conformDailogOpen(nextLink) {
      if (this.$route.name == "BillingInvoiceCreate") {
        this.conformDailog = true;
        this.link = nextLink;
      } else {
        this.$router.push(nextLink);
      }
    },
    goToNext() {
      this.conformDailog = false;
      this.$router.push(this.link);
    },
  },
};
</script>
